<template>
  <div style="padding: 0px 15px">
    <div class="result-success">
      <div class="result-success-icon">
        <van-image
          :src="require('@/assets/icons/icon_success.png')"
          width="85"
          height="85"
        />
      </div>
      <div class="result-success-title">
        {{ title }}
      </div>
    </div>

    <div class="customer-service">
      {{ description }}
    </div>
    <div class="action-bar">
      <van-button type="default" @click="gotoHomePage">{{
        $t("Home Page")
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { Button, Toast, Image as VanImage, Popup } from "vant";
import { SUCCESS } from "@/utils/constants";

export default {
  components: {
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [Popup.name]: Popup,
  },
  data() {
    return {
      successType: this.$route.query.successType,
      title: null,
      description: null,
    };
  },
  methods: {
    gotoHomePage() {
      this.$store.commit("setHomeIndex", 0);
      this.$router.push({ name: "Home" });
    },
  },
  mounted() {
    switch (this.successType) {
      case SUCCESS.ORDER:
        this.title = this.$t("Ordered successfully");
        this.description = this.$t(
          "If you have any questions, feel free to contact our customer service via Line Offical @beericher"
        );
        break;
      case SUCCESS.SLIP_UPLOAD:
        this.title = this.$t("Slip uploaded successfully");
        this.description = this.$t(
          "If you have any questions, feel free to contact our customer service via Line Offical @beericher"
        );
        break;
      case SUCCESS.CANCEL_UNPAY:
        this.title = this.$t("Order cancelled successfully");
        this.description = this.$t(
          "If you have any questions, feel free to contact our customer service via Line Offical @beericher"
        );
        break;
      case SUCCESS.CANCEL_PAID_UNSHIP:
        this.title = this.$t("Order cancelled successfully");
        this.description = this.$t(
          "You will receive your refund within 3 business days. If you have any questions, please contact us via Line Offical @beericher"
        );
        break;
      case SUCCESS.CANCEL_PAID_SHIP:
        this.title = this.$t("Order cancelled successfully");
        this.description = this.$t(
          "You will receive your refund upon receipt of the returned goods by the supplier. The shipping cost to send the goods back to the supplier will be deducted from your refund.  If you have any questions, please contact us via Line Offical @beericher"
        );
        break;
      default:
        break;
    }
  },
};
</script>
<style lang="less" scoped>
page {
  background-color: #fff;
}

.result-success {
  padding: 15px 0;
}

.result-success-icon {
  margin-bottom: 18px;
  text-align: center;
}

.result-success-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.result-success-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.customer-service {
  color: #555;
  padding: 10px 15px;
}

.action-bar {
  margin-top: 20px;
  text-align: center;
}

.redpacket {
  width: 90px;
  height: 90px;
}

.redpacket-btns {
  margin-top: 5px;
  display: flex;
  border-top: 1px solid #ebedf0;
}

.redpacket-btn {
  flex: 1;
  padding: 5px 0;
}

.van-popup {
  border-radius: 10px;
}
</style>